import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Login, sendCodeVerificationCode } from "../../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import "./login.css";
import {
  ORGANIZATION_DETAILS_SUCCESS,
  ORGANIZATION_LIST_SUCCESS,
} from "../../redux/constants/OrganizationConstants";

const LoginPage = () => {
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(""); // Error state for invalid credentials
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [formState, setFormState] = useState({
    values: {},
  });

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setError(""); // Reset error state
    const { email, password } = formState.values;
    if (email && password) {
      dispatch(Login(email, password)).then((response) => {
        if (response?.message === "Invalid Email or Password") {
          setError("Invalid Email or Password. Please try again."); // Display error
        } else if (response?.message === "User Not Verified.") {
          setLoading(true);
          dispatch(sendCodeVerificationCode(email)).then((res) => {
            if (res?.success === 1) {
              setLoading(false);
              history.push(`/Verify/${res?.data?.token}`);
            } else {
              setLoading(false);
            }
          });
        } else if (
          response?.data?.role !== "Super_Admin" &&
          response?.data?.organizations.length === 0
        ) {
          history.push("/organization/create");
        } else if (response?.data?.role === "Super_Admin") {
          history.push("/organizations");
        } else {
          dispatch({
            type: ORGANIZATION_LIST_SUCCESS,
            payload: { data: response?.data?.organizations },
          });
          dispatch({
            type: ORGANIZATION_DETAILS_SUCCESS,
            payload: response?.data?.organizations[0],
          });
          history.push("/apps");
        }
      });
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="container-scroller loginInner">
        <div className="container page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
            <div className="row w-100 mx-0 loginBg">
              <div className="col-lg-5 ml-auto">
                <div className="auth-form-light text-left py-4 px-4 px-sm-5 loginBox">
                  <div className="brand-logo">
                    <img src="assets/logo/Logo.svg" alt="logo" />
                  </div>
                  <h4 className="text-center mt-5">Hello! let's get started</h4>
                  <h6 className="fw-normal text-center mt-3">
                    Sign in to continue.
                  </h6>
                  <form
                    className="pt-3"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    {/* Error Message */}
                    {error && (
                      <div className="alert alert-danger text-center">
                        {error}
                      </div>
                    )}

                    <div className="form-group">
                      <label>
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className={
                          "form-control form-control-lg" +
                          (submitted && !formState.values.email
                            ? " is-invalid"
                            : "")
                        }
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        value={formState.values.email || ""}
                        required
                      />
                      {submitted && !formState.values.email && (
                        <div className="inline-errormsg">
                          Please fill out this field.
                        </div>
                      )}
                    </div>
                    <div className="form-group position-relative">
                      <label>
                        Password <span className="required">*</span>
                      </label>

                      <input
                        type={passwordVisible ? "text" : "password"}
                        className={
                          "form-control form-control-lg" +
                          (submitted && !formState.values.password
                            ? " is-invalid"
                            : "")
                        }
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={formState.values.password || ""}
                        required
                      />
                      <i
                        onClick={togglePasswordVisibility}
                        className={
                          passwordVisible
                            ? "fa fa-eye-slash position-absolute end-0 me-2"
                            : "fa fa-eye position-absolute end-0 me-2"
                        }
                        style={{
                          top: "70%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      ></i>
                      {submitted && !formState.values.password && (
                        <div className="inline-errormsg">
                          Please fill out this field.
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn">
                        SIGN IN
                      </button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check"></div>
                      <a
                        href="/forgot-password"
                        className="auth-link text-black"
                      >
                        Forgot password?
                      </a>
                    </div>
                    <div className="text-center mt-2 fw-normal">
                      Don't have an account?{" "}
                      <Link to="/register" className="createLink">
                        Create
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default LoginPage;
