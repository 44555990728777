import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import urlConst from '../urlConsts'
import { useSelector } from "react-redux";
import { useLocation  } from 'react-router-dom';
import './Sidebar.css'
import { useEffect } from 'react';

const Sidebar = () => {
  const path = useLocation();
  const organization = useSelector((state)=>state.organizationDetails.organization)
  const {userLogin: { userInfo :{data} }} = useSelector((state) => state);

  function useActivePath(paths) {
    const location = useLocation();
    return paths.some(path => location.pathname.includes(path));
  }
  const isActiveApps = useActivePath(['/apps', '/app']);
  const isActiveUsers = useActivePath(['/users', '/user']);
  const isActiveOrganizations = useActivePath(['/organizations', '/organization']);
  const organizationuserRole = organization?.user_role;
  const [sidebarVisible, setSidebarVisible] = useState(localStorage.getItem("sidebarVisible") === "true");

  useEffect(() => {
    const handleStorageChange = () => {
      const isVisible = localStorage.getItem("sidebarVisible") === "true";
      setSidebarVisible(isVisible);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  
	return(
		<>
			<nav className={`sidebar ${sidebarVisible ? "sidebar-offcanvas" : ""}`} id="sidebar">
        <ul className="nav">
          {urlConst === 'test' ? 
            <li className="nav-item">
              <NavLink className="nav-link" to="/dashboard">
                <i className="fa fa-home menu-icon" aria-hidden="true"></i>
                <span className="menu-title">Dashboard</span>
              </NavLink>
            </li>
          : null}
             
          {data.role !== 'Super_Admin' && organizationuserRole === "Admin" && path?.pathname !== '/organization/create'? 
            <>
              <li className={`nav-item ${isActiveApps ? 'active' : ''}`} >
                <NavLink
                  className={`nav-link ${isActiveApps ? 'active' : ''}`}
                  to="/apps"
                >
                  <i class="bi bi-box-seam menu-icon"></i>
                  <span className="menu-title">Apps</span>
                </NavLink>
              </li>

              <li className={`nav-item ${isActiveUsers ? 'active' : ''}`} >
                <NavLink
                  className={`nav-link ${isActiveUsers ? 'active' : ''}`}
                  to="/users"
                >
                  <i class="bi bi-person-add menu-icon"></i>
                  <span className="menu-title">Users</span>
                </NavLink>
              </li> 

            </>
          : data.role !== 'Super_Admin' && organizationuserRole !== 'Admin' && path?.pathname !== '/organization/create' ?
            <li className={`nav-item ${isActiveApps ? 'active' : ''}`} >
              <NavLink
                className={`nav-link ${isActiveApps ? 'active' : ''}`}
                to="/apps"
              >
                <i class="bi bi-box-seam menu-icon"></i>
                <span className="menu-title">Apps</span>
              </NavLink>
            </li> 
          :  null}

          {path?.pathname === '/organization/create' ?
            <li className={`nav-item ${isActiveApps ? 'active' : ''}`} >
              <NavLink
                className={`nav-link ${isActiveApps ? 'active' : ''}`}
                to="#"
              >
                <i class="bi bi-box-seam menu-icon"></i>
                <span className="menu-title">Organization</span>
              </NavLink>
            </li>
          : null}

          {urlConst === 'test' ? 
            <li className="nav-item">
              <NavLink className="nav-link" to="/products">
                <i className="fa fa-cube menu-icon" />
                <span className="menu-title">Products</span>
              </NavLink>
            </li>
          : null}

          { data.role === 'Super_Admin' ?
            <>
              <li className={`nav-item ${isActiveOrganizations ? 'active' : ''}`} >
                <NavLink
                  className={`nav-link ${isActiveOrganizations ? 'active' : ''}`}
                  to="/organizations"
                >
                  <i class="bi bi-person-add menu-icon"></i>
                  <span className="menu-title">Organizations</span>
                </NavLink>
              </li>
            </>
          :null }
        </ul>
      </nav>
		</>
	)
}


export default Sidebar;