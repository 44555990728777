import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updatePassword } from '../../redux/actions/userActions'; 
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import { Circles } from 'react-loader-spinner'; 

const UserPassword = () => {
    const [loading, setLoading] = useState(false); 
    const [submitted, setSubmitted] = useState(false);  
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newpasswordVisible, setNewPasswordVisible] = useState(false);
    const [formState, setFormState] = useState({
         values: { 
            current_password: '',
            new_password: ''
         },
         errors: {
            current_password: 'Password Required',
            new_password: 'Password Required'
         }
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
          values: {
            ...prevState.values,
            [name]: value
          },
          errors: {
            ...prevState.errors,
            [name]: validatePassword(name, value)
          }
        }));
      };

      const validatePassword = (name, password) => {
         if (!password) {
           if(name === 'current_password'){
             return `Current_Password is required`;
           } else if(name === 'new_password'){
             return `New Password is required`;
           }
                 
         } else if (password.length < 6 || password.length > 15) {
           if(name === 'current_password'){
             return `Current_Password must be between 6 and 15 characters`;
           } else if(name === 'new_password'){
             return `New Password must be between 6 and 15 characters`;
           }
         } else {
           return null;
         }
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();

        const { current_password, new_password } = formState.values;

        if (!formState.errors.current_password && formState.values.current_password) {
            if (!formState.errors.new_password && formState.values.new_password) {
               console.log('Form is valid');
               setSubmitted(true); 
               setLoading(true)
               dispatch(updatePassword(current_password, new_password)).then((response)=>{
                  setFormState({
                     values:{ 
                        current_password: '',
                        new_password: ''
                     },
                     errors: {}
                  });
                  setSubmitted(false);
                  setLoading(false)
               });      
            } else {
               alert(formState.errors.new_password) 
            }
        } else {
            console.log('Form has errors');
            alert(formState.errors.current_password)
        }
      }

      const togglePasswordVisibility = () => {
         setPasswordVisible(!passwordVisible);
      };

      const toggleNewPasswordVisibility = () => {
         setNewPasswordVisible(!newpasswordVisible);
      };

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">
				                        <h4 className="card-title">Update Password</h4>
				                        <form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    {/* <label className="col-form-label">Current Password
                                                      <span style={{ color: 'red' }}>*</span>
                                                    </label> */}
                                                    <label>Current Password <span className="required">*</span></label>

				                                    <div className="">
                                                <div className="input-group">
									                        <input  type={passwordVisible ? 'text' : 'password'} className={'form-control form-control-lg' + (submitted && !formState.values.current_password ? ' is-invalid' : '')} 
					                                        name="current_password"                                
					                                        onChange={handleChange}
					                                        value={formState.values.current_password || ''}
					                                        />
                                                         <i onClick={togglePasswordVisibility}
                                                            className={passwordVisible ? 'fa fa-eye-slash position-absolute end-0 me-2' : 'fa fa-eye position-absolute end-0 me-2'}
                                                            style={{ top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                         ></i>

                                                </div>
					                                        {submitted && !formState.values.current_password &&
					                                            <div className="inline-errormsg">Current Password is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>
                                          <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">New Password
                                                       <span style={{ color: 'red' }}>*</span>
                                                    </label>
				                                    <div className="">
                                                  <div className="input-group">
                                                    <input
                                                       type={newpasswordVisible ? 'text' : 'password'}
                                                       className={'form-control form-control-lg' + (submitted && !formState.values.new_password ? ' is-invalid' : '')}
                                                       name="new_password"
                                                       onChange={handleChange}
                                                       value={formState.values.new_password || ''}
                                                    />
                                                       <i onClick={toggleNewPasswordVisibility}
                                                            className={newpasswordVisible ? 'fa fa-eye-slash position-absolute end-0 me-2' : 'fa fa-eye position-absolute end-0 me-2'}
                                                            style={{ top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                         ></i>
                                                    </div>
                                                   
                                                    {submitted && !formState.values.new_password && (
                                                       <div className="invalid-feedback">New Password is required</div>
                                                    )}
                                                    {submitted && formState.errors.new_password && (
                                                       <div className="invalid-feedback">{formState.errors.new_password}</div>
                                                    )}

				                                    </div>
				                                 </div>
				                              </div>					                              
				                           </div>
				                            <div className="text-left">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-lg-2 mr-0 mb-3 mb-lg-0">Submit</button>
                    							<Link to="/apps"><button className="ButtonLight col-sm-2 col-form-label text-center ml-lg-2 ml-0">Cancel</button></Link>
                    						</div>
				                        </form>
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
         {loading &&  <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>}
		</>
		)
}

export default UserPassword;