import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../logo/Logo.svg';

const LandingPage = () => {
    const history = useHistory();
    const env_type = process.env.REACT_APP_ENVIRIONMENT;
    const handleLogin = () => {
        history.push('/login');
    };

    const handleSignUp = () => {
        history.push('/register');
    };

    return (
        <>
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className='container'>
                    <a className="navbar-brand" href="/#"><img src="assets/logo/Logo.svg" alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            {env_type === 'STAGING' ?
                              <li className="nav-item">
                                <a className="nav-link" href="#priceDiv">Pricing</a>
                              </li>
                            : null}
                           
                            <li className="nav-item">
                                <a className="nav-link" href="#aboutUs">About Us</a>
                            </li>
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link getLink" href="/register">🚀 Start free</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
            <div className="laningPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="landingBox">
                                <h1>Welcome to</h1>
                                <h2>Beta Appstore</h2>
                                <p>The hub for innovators—create apps, upload builds, and collaborate with your team. Streamline your development process on one platform.
                                </p>
                                <button onClick={handleLogin} className="loginBtnCss">Sign In</button>
                                <button onClick={handleSignUp} className="loginBtnCss">Sign Up</button>
                              
                            </div>
                        </div>
                        <div className="col-lg-6 desktop-only">
                            <div className="welcomeImg">
                                <img src="assets/images/landing_Img.png" alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whiteWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className='headStyle'>Beta Appstore excels at</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/apple.png" alt="logo" />
                               <h3>iOS apps</h3>
                               <h4>Swift and Objective-C</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 mt-4 mt-lg-0">
                            <div className="appBox">
                               <img src="assets/images/android.png" alt="logo" />
                               <h3>Android apps</h3>
                               <h4>Java, React Native and Kotlin</h4>
                            </div>
                        </div>

                        {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/windows.png" alt="logo" />
                                <h3>Windows apps</h3>
                                <h4>UWP, WPF and WinForms</h4>
                            </div>
                        </div> */}

                        {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/react.png" alt="logo" />
                                <h3>React Native apps</h3>
                                <h4>iOS and Android</h4>
                            </div>
                        </div> */}

                        {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/xamarin.png" alt="logo" />
                                <h3>Xamarin apps</h3>
                                <h4>iOS and Android</h4>
                            </div>
                        </div> */}

                        {/* <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/apps.png" alt="logo" />
                                <h3>Even more!</h3>
                                <h4>macOS, tvOS and Unity</h4>
                            </div>
                        </div> */}
                    
                    </div>
                </div>
            </div>

            {/* <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>Connect your source control, automate your workflows, and manage app releases with ease.
                            </h2>
                            <p className='text-center pStyle'>Build in the cloud, run tests, and deploy updates to testers or app stores with full integration for popular repositories.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <img src="assets/images/start.jpeg" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-5 mt-4">
                            <h2 className='headStyleTwo'>Quick Start, Maximum Efficiency</h2>
                            <p className='pStyle'>Getting started is easy — Beta Appstore’s smart analyzer handles iOS, Android, and React Native builds, ensuring you’re ready to upload in no time.</p>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Enhanced Collaboration Tools
                            </h2>
                            <p className='pStyle'>Boost productivity with seamless collaboration. Invite team members, assign roles, and streamline app development under one unified platform. Stay informed with notifications sent to developers and testers whenever a new build is uploaded.</p>
                        </div>
                        <div className="col-lg-7">
                        <img src="assets/images/image1.png" alt="logo" className='w-100' style={{ height: '372px', width: '743px', objectFit: 'cover' }}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-lg-7">
                            <img src="assets/images/smart_app.jpeg" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-5 mt-4">
                            <h2 className='headStyleTwo'>Smarter App Distribution</h2>
                            <p className='pStyle'>Automate your releases with ease. Assign testers and let Beta Appstore deliver updates automatically every time your branch gets a new merge.</p>
                        </div>
                       
                    </div>
                </div>
            </div>

            {/* <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Customizable CI/CD Made Simple
                            </h2>
                            <p className='pStyle'>Streamline your workflow: push to a feature branch for builds and tests, a beta branch for tester distribution, and the master branch for App Store submissions. Fully adaptable to suit your team’s needs.</p>
                        </div>
                        <div className="col-lg-7">
                        <img src="assets/images/ci-cd.jpeg" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>One-Click OTA Installation
                            </h2>
                            <p className='pStyle'>Distribute builds directly to testers and stakeholders via over-the-air (OTA) updates. Provide iOS .ipa and Android .apk files with just a single click for streamlined user feedback.</p>
                        </div>
                        <div className="col-lg-7">
                        <img src="assets/images/ota.jpeg" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <img src="assets/images/image2.png" alt="logo" className='w-100' style={{ height: '372px', width: '743px', objectFit: 'cover' }}  />
                        </div>
                        <div className="col-lg-5 mt-4">
                            <h2 className='headStyleTwo'>Comprehensive Platform Support</h2>
                            <p className='pStyle'>From Android to iOS and React Native, Beta Appstore has you covered. Our platform is designed to handle diverse build requirements, helping your team focus on innovation.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="greyWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Compliance and Security</h2>
                            <p className='pStyle'>Ensure your builds meet security and compliance standards. Beta Appstore offers encrypted data storage, activity logging, and customizable access controls.</p>
                        </div>
                        <div className="col-lg-7">
                            <img src="assets/images/security.jpeg" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle'>You’ll go a long way, for free.</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/build.png" alt="logo" />
                               <h3>Build</h3>
                               <h4>240 build minutes per month</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 mt-4 mt-lg-0">
                            <div className="appBox">
                               <img src="assets/images/distributed.png" alt="logo" />
                               <h3>Distribute</h3>
                               <h4>Unlimited distributions and users</h4>
                            </div>
                        </div>

                        {/* <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/report.png" alt="logo" />
                               <h3>Crash Reporting</h3>
                               <h4>All features included</h4>
                            </div>
                        </div> */}

                        <div className="col-lg-2 mt-4 mt-lg-0">
                            <div className="appBox">
                                <img src="assets/images/30-days.png" alt="logo" />
                               <h3>Test</h3>
                               <h4>Free 30 day trial</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 mt-4 mt-lg-0">
                            <div className="appBox">
                                <img src="assets/images/analytics.png" alt="logo" />
                               <h3>Analytics</h3>
                               <h4>All features included</h4>
                            </div>
                        </div>

                        <div className="col-lg-2 mt-4 mt-lg-0">
                            <div className="appBox">
                                <img src="assets/images/general.png" alt="logo" />
                               <h3>General</h3>
                               <h4>Unlimited apps, organizations, and teams</h4>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="darkYellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h2 className='headStyle text-left mb-4'>Find bugs on any device before release.</h2>
                            <p className='pStyle'>Wondering if your app still functions after recent changes? Curious about its performance on an LG G2? Test your app in a hosted device lab with thousands of real iOS and Android devices. You'll receive detailed test results, full-resolution screenshots of each step, and performance metrics.</p>

                            <p className='pStyle'>Validate every feature, on every device, with every commit. Welcome to the next generation of app testing.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src="assets/images/test-devices.png" alt="logo" className='w-100' />
                            
                        </div>
                    </div>
                </div>
            </div> */}

            <div id='aboutUs'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-5 aboutImgWrap">
                            {/* <img src="assets/images/test-devices.png" alt="logo" className='w-100' /> */}
                            
                        </div>
                        <div className="col-lg-7">
                            <div className='aboutDetail'>
                            <h2 className='headStyle text-left mb-4 mt-4'>About Beta Appstore</h2>
                            <p className='pStyle'>
                                Welcome to <b>Beta Appstore</b>,
                                your ultimate destination for seamless app distribution and testing.
                                Designed with developers and testers in mind,
                                our platform simplifies the process of sharing and managing beta builds,
                                enabling on-the-fly installation of APKs and IPAs directly onto devices.
                            </p>

                            <br></br>

                            <h2 className='headStyle text-left mb-4'>Why Beta Appstore?</h2>
                            <p className='pStyle'>
                                In the fast-paced world of app development,
                                efficiency and reliability are key.  
                                <b> Beta Appstore</b> bridges the gap between development and deployment, 
                                offering a robust platform for:
                                <br></br>
                                <ul>
                                    <li>
                                        <p>
                                            <b>Easy Distribution: </b> 
                                            Share your app builds with team members, testers, or clients effortlessly.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Device Compatibility: </b> 
                                            Install apps on both Android and iOS devices with minimal setup.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Streamlined Testing: </b> 
                                            Empower testers to access the latest builds with a simple link or QR code.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Automation-Ready (Coming soon): </b> 
                                            Integrate with CI/CD tools like GitHub Actions, Bitbucket CI/CD, and CircleCI to automatically push builds to the store, ensuring your team always works with the latest version.
                                        </p>
                                    </li>
                                </ul>
                            </p>

                            <br></br>

                            <h2 className='headStyle text-left mb-4'>Who is it for?</h2>
                                <ul>
                                    <li>
                                        <p>
                                            <b>Developers:  </b> 
                                            Simplify the deployment process and focus on building great apps.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Testers: </b> 
                                            Access, test, and provide feedback on the latest builds with ease.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Project Managers / Production Owners / Mobile App Owners: </b> 
                                            Ensure smooth communication and delivery across teams.
                                        </p>
                                    </li>
                                </ul>
                            
                            <h2 className='headStyle text-left mb-4'>Key Features</h2>
                            <ul>
                                <li>
                                    <p>
                                        <b>On-the-Air Installation:  </b> 
                                        Install apps directly on devices without complicated provisioning.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Centralized Build Management: </b> 
                                        Manage multiple app versions in one place.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Customisable Access Control: </b> 
                                        Securely share builds with select users.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Detailed Analytics: </b> 
                                        Gain insights into downloads, feedback, and app performance.
                                    </p>
                                </li>
                            </ul>
                            <p className='pStyle'>
                            Whether you're a startup, an enterprise, or an indie developer, <b>Beta Appstore</b> helps you accelerate your app development lifecycle, ensuring high-quality releases with every iteration.
                            Join us and redefine how you distribute and test apps. <b>Beta Appstore</b> is here to make your journey from code to launch effortless and efficient.
                            </p>
                            <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>Fast Updates, Smooth Releases
                            </h2>
                            <p className='text-center pStyle'>Enhancements and fixes go live effortlessly with Beta Appstore. Whether it’s private distribution, open beta testing, or publishing to Google Play, TestFlight, or the App Store, we’ve got you covered.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

           

            {/* <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <img src="assets/images/distribute.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-7">
                            <h2 className='headStyleTwo'>Promote validated builds to app stores</h2>
                            <p className='pStyle'>Once you’ve beta tested a release candidate, promote the same build directly to Intune, Google Play, or App Store Connect. Beta Appstore tracks your builds through every release.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle mb-4'>Collect real-time diagnostics,
                            prioritize and fix critical issue.
                            </h2>
                            <p className='text-center pStyle'>Integrate the Beta Appstore SDK for automatic crash reporting. Build your app in Beta Appstore, and get automatic symbol management for richer reports.
                            </p>
                            <img src="assets/images/diagnostics.png" alt="logo" className='mt-5'/>
                            <p className='pStyle mt-5'>
                            Crash reports are grouped by common cause, highlighting the relevant stack frame so you can locate errors by file and
                            line number. Search specific users' crashes and browse individual reports for event breadcrumbs and custom data attachments.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>App analytics, made for developers.</h2>
                            <p className='text-center pStyle'>Developer-friendly analytics make it easy to discover how to improve your apps.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <img src="assets/images/app-center-live-analytics@2x.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Live analytics for sessions, events, crashes, and more</h2>
                            <p className='pStyle'>See streaming activity for sessions and events immediately, and collect metrics that become more useful over time.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Who? What? Where? When? Why? How?</h2>
                            <p className='pStyle'>Is your audience growing? What devices and operating systems are most common? What features are popular? Get straightforward answers to the questions you care about.</p>
                        </div>
                        <div className="col-lg-7">
                            <img src="assets/images/analytics-graphs.png" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle mb-3'>You’re in good company.</h2>
                            <p className='pStyle'>These companies already use Beta Appstore.</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-2">
                            <div className="companyBox">
                               <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                               <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div> */}

        
            {env_type === 'STAGING' ?
            <div className="darkYellowWrap" id='priceDiv'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='cartWrap'>
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="cartBox">
                                            <h3>Scale up with additional concurrent builds as your team expands.</h3>
                                            <p>Accelerate your app delivery by running multiple builds simultaneously.</p>
                                            <h4>&#x20b9;40<span>/month</span></h4>
                                            <h5>per build concurrency</h5>
                                            <a href='/#'>Get Started</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 mt-lg-0">
                                        <div className="cartBox">
                                            <h3>Run tests concurrently on a wider range of devices.</h3>
                                            <p>Execute UI tests on thousands of real devices and numerous configurations.</p>
                                            <h4>&#x20b9;99/<span>/month</span></h4>
                                            <h5>per standard device concurrency</h5>
                                            <a href='/#'>Get Started</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 mt-lg-0">
                                        <div className="cartBox">
                                            <h3>Test on more devices in parallel</h3>
                                            <p>Run UI test on thousands of real devices and hundreds of configurations.</p>
                                            <h4>&#x20b9;199/<span>/month</span></h4>
                                            <h5>per standard device concurrency</h5>
                                            <a href='/#'>Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : null}

            <footer className='landingFooter'>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-center'>
                            <img src={logo} alt="logo" className='footerLogo mr-3' />
                            <p>Copyright © 2024. All rights reserved to Barquecon Technologies Pvt Ltd.</p>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className='d-flex justify-content-end'>
                                <a href='/#'>Terms of use</a>
                                <a href='/#'>Privacy & cookies</a>
                                {/* <a href='/#'>Trademarks</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default LandingPage;
