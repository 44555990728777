import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Register } from "../../redux/actions/userActions";
import { Circles } from "react-loader-spinner";

const RegisterPage = ({ history }) => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    values: {},
  });

  // Update form values dynamically
  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;

    // Validation for name field to allow only alphanumeric and spaces
    if (name === "name") {
      const regex = /^[a-zA-Z0-9\s]*$/;
      if (!regex.test(value)) return;
    }

    setFormState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  // Submit form with validation and loading state
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    const { name, email, password } = formState.values;
    if (!name || !email || !password) return; // Prevent submission if form is invalid

    setLoading(true); // Start loader
    dispatch(Register(name, email, password)).then((response) => {
      setLoading(false); // Stop loader
      if (response?.data?.token) {
        history.push(`/Verify/${response.data.token}`);
      }
    });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="container-scroller loginInner">
        <div className="container page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
            <div className="row w-100 mx-0 loginBg">
              <div className="col-lg-5 ml-auto">
                <div className="auth-form-light text-left py-3 px-4 px-sm-5 loginBox">
                  <div className="brand-logo">
                    <img src="assets/logo/Logo.svg" alt="logo" />
                  </div>
                  <h4 className="text-center mt-5">New here?</h4>
                  <h6 className="fw-normal text-center mt-3">
                      Signing up is easy. It only takes few steps to open your account
                    {/* Signing up is easy. It only takes a few steps */}
                  </h6>
                  <form
                    className="pt-3"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    {/* Name Field */}
                    <div className="form-group">
                      <label>
                        Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          "form-control form-control-lg" +
                          (submitted && !formState.values.name
                            ? " is-invalid"
                            : "")
                        }
                        name="name"
                        placeholder="Name"
                        onChange={handleChange}
                        value={formState.values.name || ""}
                        required
                      />
                      {submitted && !formState.values.name && (
                        <div className="inline-errormsg">Name is required</div>
                      )}
                    </div>

                    {/* Email Field */}
                    <div className="form-group">
                      <label>
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className={
                          "form-control form-control-lg" +
                          (submitted && !formState.values.email
                            ? " is-invalid"
                            : "")
                        }
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        value={formState.values.email || ""}
                        required
                      />
                      {submitted && !formState.values.email && (
                        <div className="inline-errormsg">Email is required</div>
                      )}
                    </div>

                    {/* Password Field */}
                    <div className="form-group position-relative">
                      <label>
                        Password <span className="required">*</span>
                      </label>
                      <input
                        className={
                          "form-control form-control-lg" +
                          (submitted && !formState.values.password
                            ? " is-invalid"
                            : "")
                        }
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={formState.values.password || ""}
                        required
                      />
                      <i
                        onClick={togglePasswordVisibility}
                        className={
                          passwordVisible
                            ? "fa fa-eye-slash position-absolute"
                            : "fa fa-eye position-absolute"
                        }
                        style={{
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      ></i>

                      {submitted && !formState.values.password && (
                        <div className="inline-errormsg">
                          Password is required
                        </div>
                      )}
                    </div>

                    {/* Submit Button */}
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn"
                        type="submit"
                      >
                        SIGN UP
                      </button>
                    </div>

                    <div className="text-center mt-3 fw-normal">
                      Already have an account?{" "}
                      <Link to="/login" className="createLink">
                        Sign In
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default RegisterPage;
