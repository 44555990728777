import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ForgotPassword } from "../../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { Circles } from "react-loader-spinner"; // Import the loader component
import "./main.css";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    values: {},
  });

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loader

    const { email } = formState.values;
    dispatch(ForgotPassword(email)).then((response) => {
      setLoading(false); // Stop loader after response
      if (response.success === 1) {
        navigateLogin();
      }
    });
  };

  const navigateLogin = () => {
    history.push("/login");
  };

  return (
    <>
      <div className="container-scroller loginInner">
        <div className="container page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
            <div className="row w-100 mx-0 loginBg">
              <div className="col-lg-5 ml-auto">
                <div className="auth-form-light text-left py-4 px-4 px-sm-5 loginBox">
                  <div className="brand-logo">
                    <img src="assets/logo/Logo.svg" alt="logo" />
                  </div>
                  <h4 className="text-center mt-5">Forgot Password?</h4>
                  <h6 className="fw-normal text-center mt-3">
                    Enter your email address, and we will send you an email to recover your password.
                    {/* Enter your email ID, and we will send an email to recover your password. */}
                  </h6>
                  <form className="pt-3" onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group">
                      <label>
                        Email<span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        value={formState.values.email || ""}
                        required // Add the 'required' attribute
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? "Processing..." : "SEND RESET LINK"}
                      </button>
                    </div>
                    <div className="text-center mt-4 fw-normal">
                      <Link to="/login" className="createLink">
                        Go Back to Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Fullscreen loader */}
      {loading && (
        <div className="fullscreen-loader">
          <Circles color="#F1B621" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default ForgotPasswordPage;
